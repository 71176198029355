
// @ts-check
import waitRemove from './waitRemove';
import createHistory from './createHistory.js';
const {
	Vue,
	VueRouter,
	agGrid,
	'ag-grid-vue3': agGridVue3,
	ElementPlus,
	ElementPlusIconsVue,
	ElementPlusLocaleZhCn,
	docxtemplater,
	ImageModule,
	DocxtemplaterHtmlModule,
	VueDemi,
	VueUse,
	Pinia,
	Viewer,
	VueViewer,
	echarts,
	Handsontable,
	HyperFormula,
	XLSX,
} = window;

const {createApp} = Vue;
const {createPinia} = Pinia;
Vue.createApp = function (...p) {
	const app = createApp(...p);
	app.use(createPinia());
	return app;
};

const {h, unref, KeepAlive, ref, computed} = Vue;
const {createRouter, RouterView} = VueRouter || {};
const {ElConfigProvider} = ElementPlus;

function mountVueToPage(
	parent,
	//: HTMLElement,
	main,
	//: any,
	{
		base = '',
		replace = false,
		props = {},
		provided = {},
		plugins = [],
		components = {},
	} = {},
	// : {
	// 	props?: Record<string, any>;
	// 	provided?: Record<string, any>;
	// 	plugins?: Plugin[];
	// 	components?: Record<string, any>,
	// } = {}
) {
	const $parent = $(parent);
	const locale = computed(() => ElementPlusLocaleZhCn);

	const domVisible = ref(!$parent.is(':hidden'));
	let onShow = () => {
		domVisible.value = true;
	};
	let onHide = () => {
		domVisible.value = false;
	};
	const render = Array.isArray(main) ? () => domVisible.value ? h(RouterView) : undefined
		: () => domVisible.value ? h(main, Object.fromEntries(Object.entries(props).map(([k, v]) => [k, unref(v)]))) : undefined;
	const keepRender = () => h(KeepAlive, {}, render);
	const app = createApp({
		setup() {
			return () => h(ElConfigProvider, {locale: locale.value}, keepRender);
		},
	});
	for (const [k, v] of Object.entries(provided)) {
		app.provide(k, v);
	}
	app.provide(Symbol.for('guigu:domVisible'), domVisible);
	if (Array.isArray(main)) {
		const baseUrl = base || (() => {
			const list = location.pathname.split('/').filter(Boolean);
			if (list.length > 2) {
				list.length = 2;
			}
			list.unshift('');
			return list.join('/');
		})();
		const history = createHistory(baseUrl, replace);
		onShow = () => {
			domVisible.value = true; history.show(true);
		};
		onHide = () => {
			domVisible.value = false; history.hide();
		};
		app.use(createRouter({history, routes: main}));
	}
	$parent.on('show', onShow);
	$parent.on('hide', onHide);
	app.use(createPinia());
	for (const [name, component] of Object.entries(components)) {
		app.component(name, component);
	}
	for (const plugin of plugins) {
		app.use(plugin);
	}
	const el = parent.appendChild(document.createElement('div'));
	app.mount(el);
	waitRemove(parent, () => {
		domVisible.value = !$parent.is(':hidden');
	}).then(() => {
		$parent.off('show', onShow);
		$parent.off('hide', onHide);
		app.unmount();
	});
	return app;
}

window.require = function require(name) {
	switch (name) {
		case 'vue': return Vue;
		case 'vue-router': return VueRouter;
		case 'element-plus': return ElementPlus;
		case 'element-plus/dist/locale/zh-cn': return ElementPlusLocaleZhCn;
		case '@element-plus/icons-vue': return ElementPlusIconsVue;
		case 'vue-demi': return VueDemi;
		case '@vueuse/shared': return VueUse;
		case '@vueuse/core': return VueUse;
		case 'pinia': return Pinia;
		case 'viewerjs': return Viewer;
		case 'v-viewer': return VueViewer;
		case 'ag-grid-community': return agGrid;
		case 'ag-grid-enterprise': return agGrid;
		case 'ag-grid-vue3': return agGridVue3;
		case 'guigu:module': return {mountVueToPage};
		case 'echarts': return echarts;
		case 'handsontable/base': return Handsontable;
		case 'handsontable': return Handsontable;
		case '@handsontable/vue': return Handsontable.vue;
		case 'hyperformula': return HyperFormula;
		case 'xlsx': return XLSX;
		case 'xlsx-js-style': return XLSX;
		case 'docxtemplater-image-module': return ImageModule;
		case 'docxtemplater-html-module': return DocxtemplaterHtmlModule;
		case 'docxtemplater': return docxtemplater;
	}
};
