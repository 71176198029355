function isAdded(a: Node) {
	let p = a.parentElement;
	while (p) {
		if (p === document.body) { return true; }
		p = p.parentElement;
	}
	return false;
}
const map = new Map<Node, {
	state: boolean;
	add: (() => void)[];
	remove: (() => void)[];
}>();
const mo = new MutationObserver(() => {
	const cb: (() => void)[][] = [];
	for (const [n, c] of [...map]) {
		const newState = isAdded(n);
		if (newState === c.state) { continue; }
		c.state = newState;
		cb.push(newState ? c.add : c.remove);
		if (!newState) { map.delete(n); }
	}
	for (const fn of cb.flat()) {
		fn();
	}
});
mo.observe(document.body, {
	subtree: true,
	childList: true,
	attributes: false,
});

export default function waitRemove(
	node: Node,
	added: () => void = () => { }
): Promise<void> {
	return new Promise(res => {
		let s = map.get(node);
		if (!s) {
			s = { state: isAdded(node), add: [], remove: [] };
			map.set(node, s);
		}
		s.remove.push(res);
		if (s.state) {
			added();
		} else {
			s.add.push(added);
		}
	});


}
