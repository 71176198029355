export enum NavigationDirection {
	back = 'back',
	forward = 'forward',
	unknown = ''
}
export enum NavigationType {
	pop = 'pop',
	push = 'push'
}
export interface NavigationInformation {
	type: NavigationType;
	direction: NavigationDirection;
	delta: number;
}
export interface NavigationCallback {
	(to: string, from: string, information: NavigationInformation): void;
}
export interface RouterHistory {
	readonly base: string;
	readonly location: string;
	readonly state: object;
	push(to: string): void;
	replace(to: string): void;
	go(delta: number, triggerListeners?: boolean): void;
	listen(callback: NavigationCallback): () => void;
	createHref(location: string): string;
	destroy(): void;
	show(isSet: boolean): void;
	hide(): void;
}


function isStartsWith(base: string) {
	if (!base) { return true; }
	const {pathname} = window.location;
	if (pathname === base) { return true; }
	return pathname.startsWith(`${base}/`);
}

const BEFORE_HASH_RE = /\/*[#?].*$|\/+$/;
export default function createHistory(baseUrl: string, replace = false): RouterHistory {
	const base = `/${baseUrl.split('/').filter(Boolean).join(('/'))}`.replace(BEFORE_HASH_RE, '');
	function getPath() {
		const { pathname, search, hash } = location;
		const path = isStartsWith(base) && pathname.slice(base.length) || '/';
		return path + search + hash;
	}
	const queue = [getPath()];
	let position = 0;
	const listeners: NavigationCallback[] = [];
	function emit(from: string, delta: number) {
		const to = queue[position];
		const info = {
			// eslint-disable-next-line no-nested-ternary
			delta, type: NavigationType.pop, direction: delta
				? delta < 0
					? NavigationDirection.back
					: NavigationDirection.forward
				: NavigationDirection.unknown,
		};
		for (const listener of listeners) {
			listener(to, from, info);
		}
	}
	function setPath() {
		if (!isStartsWith(base)) { return; }
		history.replaceState({}, '', base + queue[position]);
	}

	function setLocation(to: string, replace = false) {
		if (!replace) { position++; }
		queue.length = position;
		queue[position] = to;
		setPath();
	}
	return {
		get location() { return queue[position]; },
		state: {}, base,
		createHref(location) { return base + location; },
		push(to) { setLocation(to); },
		replace(to) { setLocation(to, true); },
		go(delta, shouldTrigger = true) {
			const from = queue[position];
			position = Math.max(0, Math.min(position + delta, queue.length - 1));
			if (shouldTrigger) { emit(from, delta); }
			setPath();
		},
		listen(callback: NavigationCallback): () => void {
			listeners.push(callback);
			const teardown = () => {
				const index = listeners.indexOf(callback);
				if (index > -1)
				{ listeners.splice(index, 1); }
			};
			return teardown;
		},
		show(isSet) {
			const to = getPath();
			const from = queue[position];
			if (from === to) { return; }
			if (!isSet) {
				const max = Math.max(queue.length - position - 1, position);
				for (let delta = 1; delta <= max; delta++) {
					if (queue[position + delta] === to) {
						position += delta;
						emit(from, delta);
						return;
					} else if (queue[position - delta] === to) {
						position -= delta;
						emit(from, -delta);
						return;
					}
				}
			}
			if (!replace) { position++; }
			queue.length = position;
			queue[position] = to;
			emit(from, replace ? 0 : 1);
		},
		hide() {

		},
		destroy() {
			listeners.length = 0;
			queue.length = 1;
			position = 0;
		},
	};
}
